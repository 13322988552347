/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

var objectToQuery = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
String.prototype.queryToObject = function() {
  query = this.split("?");
  query = query[1] ? query[1] : query[0];
  if (!query) return {};
  try {
    return JSON.parse(
      '{"' +
        decodeURIComponent(query)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (e) {
    return {};
  }
};
Number.prototype.sigFig = function($sigFigs = 3) {
  var $exponent = Math.floor(Math.log10(Math.abs(this)) + 1);
  var $significand =
    Math.round((this / Math.pow(10, $exponent)) * Math.pow(10, $sigFigs)) /
    Math.pow(10, $sigFigs);
  return $significand * Math.pow(10, $exponent);
};
Number.prototype.currency = function($sigFigs = 3) {
  //SI prefix symbols
  if (!this) return;
  var $units = ["", " RB", " JT", " M", " T", " P", " E"];
  var $index = Math.floor(Math.log10(this) / 3);
  var value = $index ? this / Math.pow(1000, $index) : this;
  return Math.round(value.sigFig($sigFigs)) + $units[$index];
};
Number.prototype.format = function(params = {}) {
  var formatter = new Intl.NumberFormat(params.country || "id-ID", {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(this).replace(/[^0-9.,]/g, "");
};

Number.prototype.formatToParts = function(params = {}) {
  var formatter = new Intl.NumberFormat(params.country, {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(this).replace(/[^0-9.,]/g, "");
};
String.prototype.format = function(params = {}) {
  return parseFloat(this.toString()).format(params);
};
Number.prototype.bytesToSize = function() {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (this == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(this) / Math.log(1024)));
  return Math.round(this / Math.pow(1024, i), 2) + " " + sizes[i];
};
String.prototype.bytesToSize = function() {
  return parseInt(this).bytesToSize();
};
String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, "g"), replacement);
};
String.prototype.htmlDecode = function() {
  if (this == "") return "";
  var e = document.createElement("div");
  e.innerHTML = this;
  return e.childNodes[0].nodeValue;
};
File.prototype.toImage = function(callback) {
  var image = new Image();
  var fr = new FileReader();
  fr.onload = () => {
    image.onload = () => {
      if (typeof callback != "undefined") callback(image);
      this.image = image;
    };
    image.src = fr.result;
  };
  fr.readAsDataURL(this);
};

function dates(date, format, from) {
  if (format == "d") format = "D";
  if (format == "m") format = "F";
  if (format == "y") format = "Y";
  if (format == "date") format = "YYYY-MM-DD";
  if (format == "datetime") format = "YYYY-MM-DD HH:mm:ss";
  if (format == "time") format = "HH:mm:ss";
  if (format == "time2") format = "HH:mm";
  if (format == "format") format = "DD MMMM YYYY";
  if (format == "format2") format = "DD MMMM YYYY, HH:mm";
  if (format == "format3") format = "dddd, DD MMMM YYYY, HH:mm";
  if (format == "format4") format = "DD MMM YYYY";
  var dateString = moment(date).format(format);
  if (from) dateString = moment(date, from).format(format);
  return dateString != "Invalid date" ? dateString : "";
}
String.prototype.dates = function(format, from) {
  return dates(this, format, from);
};

function currency(v) {
  return (v ? v.toString() : "0").format();
}
String.prototype.wordLength = function() {
  if (!this.length) return 0;
  return this.replaceAll(/\s+/g, " ")
    .trim()
    .split(" ").length;
};
String.prototype.slugify = function() {
  return this.toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};
String.prototype.limitChar = function(limit, end = "...") {
  if (this.length <= limit) return this;
  function getLastSpace(str, length) {
    if (
      str.substr(0, length).slice(-1) === " " ||
      str.substr(0, length).slice(-1) === "."
    ) {
      return str.substr(0, length) + end;
    } else {
      if (str.length === length) return str;
      return getLastSpace(str, length + 1);
    }
  }
  return getLastSpace(this, limit);
};
String.prototype.limitWord = function(limit, end = "...") {
  var str = this.stripTags()
    .replace(/\s+/g, " ")
    .trim()
    .split(" ")
    .splice(0, limit)
    .join(" ");
  if (this.wordLength() <= limit) return str;
  return str + end;
};
String.prototype.stripTags = function() {
  var div = document.createElement("div");
  div.innerHTML = this;
  return div.textContent || div.innerText || "";
};
String.prototype.toValue = function() {
  return parseFloat(this.replace(/\./g, "").replace(/,/g, "."));
};
Number.prototype.toValue = function() {
  return parseFloat(this);
};
